<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
          <QuickTranslation
            translation-key="documentType.label"
            tag="h1"
          />
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="documentTypes"
      class=" agTable"
      :no-results-text="$t('common.ui.not_search_found')"
      :no-data-text="$t('common.ui.not_data_found')"
      :loading-text="$t('common.notification.loadData')"
      :footer-props="{
        itemsPerPageText: $t('common.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
           onkeypress="return (event.charCode != 34)" style="max-width: 25em;"
           v-on:keyup.86="replaceSearch" v-on:keyup.ctrl.86="replaceSearch"

            color="primary"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('common.titles.filter')"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn
                small
                tile
                outlined
                color="primary"
                class="mb-2"
                v-on="on"
              >
                <v-icon small color="primary">mdi-plus</v-icon>
                {{ $t("common.buttons.new") }}
              </v-btn>
            </template>
            <v-card :light="true" color="white">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceDocumentTypeName" v-on:keyup.ctrl.86="replaceDocumentTypeName"
                          color="primary"
                          v-model="editedItem.document_type_name"
                          :label="$t('documentType.fields.name')"
                          required
                          class="required"
                          :rules="requireAndMaxRules(max)"
                          :counter="max"
                          maxlength="max"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <select-companies :companies="companies" />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small tile outlined color="gray" @click="close">
                  {{ $t("common.buttons.cancel") }}
                </v-btn>
                <v-btn
                  small
                  tile
                  outlined
                  color="primary"
                  :disabled="!valid"
                  @click="save"
                >
                  {{ $t("common.buttons.save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon color="neuter" small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="neuter" small @click="deleteItemDialog(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{ title }}</v-card-title>
          <v-card-text>{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="gray" @click="dialogClose">
              {{ $t("common.buttons.cancel") }}
            </v-btn>
            <v-btn small text color="primary" @click="deleteItem">
              {{ $t("common.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import DocumentTypeService from "../../../services/DocumentTypeService";
import LanguajeService from "../../../services/LanguajeService";
import SelectCompanies from "../../../components/SelectCompanies";

export default {
  components: { SelectCompanies },
  data: () => ({
    dialog: false,
    showDialog: false,
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    valid: false,
    max: 40,
    editedIndex: -1,
    search: "",
    editedItem: {
      document_type_name: "",
      companies: [],
    },
    defaultItem: {
      document_type_name: "",
    },
    deletingItem: {},
  }),

  computed: {
    selectedCompanies: {
      get() {
        return this.$store.getters["company/selected"];
      },
      set(val) {
        this.$store.dispatch("company/selectedCompanies", val);
      },
    },
    ...mapGetters({
      documentTypes: "document_type/documentTypes",
      requireAndMaxRules: "general/requireAndMaxRules",
      languageStructure: "general/languageStructure",
      companies: "company/allCompanies",
    }),
    headers: () => [
      { text: i18n.t("documentType.fields.name"), value: "document_type_name" },
      {
        text: i18n.t("common.fields.action"),
        value: "action",
        width: 100,
        sortable: false,
        align: "end",
      },
    ],
    formTitle() {
      return (
        (this.editedIndex === -1
          ? i18n.t("common.titles.new")
          : i18n.t("common.titles.edit")) +
        " " +
        i18n.t("documentType.label")
      );
    },
  },

  async mounted() {
    await this.fetchAllCompany(this.$toast);
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      fetchListDocumentTypes: "document_type/fetchListDocumentTypes",
      fetchAllCompany: "company/fetchAllCompanies",
      saveDocumentTypes: "document_type/saveDocumentTypes",
    }),
    initialize() {
      this.fetchListDocumentTypes(this.$toast);
    },
    editItem(item) {
      this.editedIndex = this.documentTypes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.selectedCompanies = this.editedItem.companies;
      this.dialog = true;
    },
    save() {
      let item = Object.assign({}, this.editedItem);
      item.companies = this.selectedCompanies;
      // Translate
      item.language_key = LanguajeService.setKey3(
        item.document_type_name,
        item.language_key,
        !item.id ? this.languageStructure : null
      );
      // *****
      delete item["document_type_name"];
      this.saveDocumentTypes([item, this.editedIndex, this.$toast]).finally(
        () => {
          this.fetchListDocumentTypes([this.$toast]);
          this.$toast.success(i18n.t("documentType.notification.save"), {
            icon: 'mdi-check-circle',
            queueable: true
          });
          this.close();
        }
      );
    },

    deleteItem() {
      this.dialogClose();
      DocumentTypeService.delete(this.deletingItem.id)
        .then((resp) => {
          this.$toast.success(this.$t("documentType.notification.deleted"), {
            icon: 'mdi-check-circle',
            queueable: true
          });
        })
        .catch((err) => {
          if (err.message.includes("406")) {
            this.$toast.info(this.$t("documentType.notification.inUse"), {
              icon: 'mdi-information-slab-circle',
              queueable: true
            });
          } else {
            this.$toast.error(
              this.$t("documentType.notification.deletedError"),
              {
                queueable: true,
              }
            );
          }
        })
        .finally(() => {
          this.fetchListDocumentTypes([this.$toast]);
        });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.selectedCompanies = [];
      }, 300);
    },

    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },
    dialogClose() {
      this.showDialog = false;
    },
    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("common.titles.alert"),
        dialogText: i18n.t("common.notification.deleteItems"),
      });
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceSearch(event){
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceDocumentTypeName(event){
      this.editedItem.document_type_name =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
};
</script>

<style scoped></style>
