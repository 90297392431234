<template>
    <v-container fluid class="pa-0">
        <v-autocomplete :no-data-text="$t('common.fields.noDataAvailable')" small color="primary"
                        :label="$t('company.fields.selectCompanies')"
                        multiple clearable item-color="primary"
                        :items="companies"
                        item-text="name"
                        item-value="id"
                        v-model="selectedCompanies"
                        :rules="requireRules"
                        :disabled="!(companies && companies.length > 0)"
                        return-object
                        small-chips>
            <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                    <span>{{ item.name.toUpperCase() }}</span>
                </v-chip>
                <span v-if="index === 1"
                      class="grey--text caption"
                >
                    (+{{ selectedCompanies.length - 1 }} {{$t('common.titles.others')}})
                </span>
            </template>
            <template v-slot:prepend-item>
                <v-list-item>
                    <v-list-item-content class="py-0">
                        <div class="row">
                            <div class="col-6 py-0 text-right">
                                <v-btn small
                                       @click="selectedCompanies=companies"
                                       color="primary" x-small
                                       text>
                                    {{$t('common.titles.selectAll')}}
                                </v-btn>
                            </div>
                            <v-divider vertical></v-divider>
                            <div class="col-5 py-0 text-left">
                                <v-btn small @click="selectedCompanies=[]"
                                       color="primary" x-small
                                       text>
                                    {{$t('common.titles.clean')}}
                                </v-btn>
                            </div>
                        </div>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mb-2"></v-divider>
            </template>
        </v-autocomplete>
    </v-container>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "SelectCompanies",
        props: {
            companies: {
                type: Array,
                required: true
            },
        },

        /*watch: {
            selectedCompanies() {
                console.log(this.selectedCompanies)
            }
        },*/

        computed: {
            selectedCompanies: {
                get() {
                    return this.$store.getters['company/selected'];
                },
                set(val) {
                    this.$store.dispatch('company/selectedCompanies', val);
                }
            },
            ...mapGetters({
                requireRules: 'general/requireRules',
                requireAndMaxRules: 'general/requireAndMaxRules',
                languageStructure: 'general/languageStructure'
            }),
        },

        filters: {
            capitalize: (value) => {
                if (!value) return '';
                return value.toUpperCase()
            }
        }
    }
</script>

<style scoped>

</style>